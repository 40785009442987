// Import the initial Bulma variables
@import "~bulma/sass/utilities/initial-variables";
@import "~bulma/sass/utilities/functions";
@import "~bulma/sass/utilities/derived-variables.sass";

// Define Bulma default variables
$family-primary: 'Open Sans', Helvetica, Arial, sans-serif;

// Import Bulma
@import "~bulma";
